// Here you can add other styles
.btn-detail {
    color:#ffffff;
    margin-top:5px;
    background-color:green;
}
.btn-delete {
    color:#ffffff;
    margin-top:5px;
    background-color:red;
}
.media {
    width: inherit;
} 
.card {
    width: 275px;
    height: 450px;
    display:inline-block;
    margin:5px;
}
.card-banner {
    width: 275px;
    height: 270px;
    display:inline-block;
    margin:5px;
}
.right {
    float: right;
}
.appbar-report {
    background-color: green;
}
.modal-article {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.width-article {
    height: 200px;
}

.paper-article {
    position: absolute;
    background-color: #ffffff;
    border: '2px solid #000';
    padding: 10px;
}
.btn-article-edit, .btn-device-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: cornflowerblue;
    margin-right: 5px;
    margin-bottom: 5px;
}
.large {
    width: 200px;
    height: 200px;
}
.login-bg {
    background-image: 'url("../../public/assets/background/bg_login.png")';
    background-color: #01395d;
}
.background-login {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
}
.login-bg-card {
    margin: auto;
    background-color: #003050;
    border:none;
    border-radius: 10px;
    padding-bottom: 20%;
}
.login-icon {
    text-align: center;
}
.logo-login {
    margin: auto;
    width: 250px;
}
@media only screen and (max-width: 600px) {
    .login-bg-card {
        background-color: #003050;
        border:none;
        border-radius: 10px;
    }
    .logo-login {
        margin: auto;
        width: 200px;
    } 
}
.modal-title {
    text-align: center;
}
.btn-login {
    color: #003050;
    width: 150px;
    border-radius: 15px;
    background-color: #ffffff;
}
.center {
    text-align: center;
}
.card-dashboard {
    height: 200px !important;
}
.text-value {
    font-size:4em
}
.text-value-sub {
    font-size: 1.5em;
}
.circle {
    border-radius: 50px;
}
.img-user {
    width: 200px;
    object-fit: cover;
}
.detail-description {
    font-size: 20px;
    text-align: justify !important;
}
.detail-body {
    padding:10%;
    text-align: center;
}
.detail-img {
    width: 90%;
    object-fit: cover;
    margin: auto;
}
.text-left{
    text-align: left;
    margin-bottom: 20px;
}
.img-modal-banner {
    width: 300px;
    text-align: center;
    margin: auto;
}
.alertlogin {
    color:red;
    margin: 10px;
}
.searchValue {
    border: none;
    margin-bottom: 10px;
    padding: 5px 10px;
}
.img-article {
    width: 400px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}
.required-star, .alert-password {
    color: red
}
.icon-dashboard {
    float: right;
}
.forgotpassword {
    z-index: 10;
}
.repassword {
    color:orange;
}