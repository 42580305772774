.modal {
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    }
.modalpaper {
    width:40%;
}
.media {
    height: 200px;
    object-fit: cover;
}